.drawer-header {
  display: flex;
  margin-top: 1rem;
  // justify-content:space-between;
}

.drawer-header button {
  margin-right: 5px;
}

.mantine-Drawer-close {
  font-size: 2rem;
}

.mantine-Drawer-content {
  overflow-y: hidden;
}
