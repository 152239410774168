@font-face {
  font-family: 'unilever-shilling';
  src: url('./fonts/UnileverShilling.woff2') format('woff2');
  /* add other font formats here, like woff and ttf */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'unilever-shilling-bold';
  src: url('./fonts/UnileverShillingBold.woff2') format('woff2');
  /* add other font formats here, like woff and ttf */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'unilever-shilling-italics';
  src: url('./fonts/UnileverShillingItalic.woff2') format('woff2');
  /* add other font formats here, like woff and ttf */
}

@font-face {
  font-family: 'unilever-shilling-medium';
  src: url('./fonts/UnileverShillingMedium.woff2') format('woff2');
  /* add other font formats here, like woff and ttf */
}
