@import '../../../../styles/_variables.scss';
.checkStatusContainer {
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  width: 50%;
  gap: 0.5rem;
  color: $status-blue;
  height: auto;
}

.section {
  flex-grow: 1;
  padding: 1rem 0.5rem;
  background-color: $unilever-white;
  width: auto;
  text-align: center;
  border: 1px solid $section-grey;
}

.statusCheckTable {
  text-align: left;
  margin: auto;
}

h3,
.h3 {
  font-size: 1.25rem;
}

.table-component tbody tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  color: $table-body-font-color;
  padding: 10px 16px;
}
