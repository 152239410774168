@import 'src/styles/main';

.toast-component {
  // position: fixed !important;

  .toast-component-toast {
    width: 100%;

    .toast-component-header {
      background-color: $main-text-black;
      color: $unilever-white;
      padding: 18px;

      border-radius: 0;
    }

    .toast-status {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }

    .toast-content {
      width: 100%;
      margin-block-start: 2px;
      margin-inline-start: 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $unilever-white;
    }
    .toast-btn {
      border: none;
      background: none;
      color: $unilever-white;
    }
  }
}
