.mantine-DatePickerInput-icon {
    color: #808080;
    font-weight: 600;
    margin-left: 1rem;
    width: 100%;
    max-width: 45px;
}

.mantine-DatePickerInput-input {
    padding-left: 65px !important;
    font-weight: 500;
}

.date-picker {
    width: 100%;
    border-radius:0 ;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}