@import 'src/styles/main';

.container {
  width: 100%;
  height: 3rem;
  background-color: $table-header;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;

  h1 {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
  }
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1rem;
}

.warning {
  color: rgba(255, 33, 33, 1);
}

.success {
  color: $success-tag;
}
