@import "src/styles/main";

.reports-page-content {
  width:100%;
  height:85vh;
  margin:0px;
  padding:40px 60px 0px 27px;
  background-color: $dashboard-bg;
  .cards-container{
    margin-bottom: 54px;
    // background-color: red;
  }
}
@media (min-width: 1500px) {
  .cards-container {
    max-width: 1300px;
    // display: flex;
    // flex-wrap: nowrap;
    // height: 260px;
    // overflow: auto;
  }
}
