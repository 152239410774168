@import "src/styles/main";

.file-name{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0.8rem;
    font-size: 1rem;
}

.file-name p {
    margin: 0;
}

.segmented-tab{
    margin: 1rem 0;
}