@import "src/styles/variables";

.update-target-modal {
  .header-container {
    background-color: $unilever-white;
    border-radius: 0px;
    padding: 20px 20px 8px 20px;

    .modal-heading {
      padding: 0px;

      p {
        font-family: 'unilever-shilling-medium';
        font-size: 18px;
      }
    }
  }

  .modal-footer {
    background-color: $unilever-white;
    border-radius: 0px;
  }

  .body-container {
    width: 100%;
    height: 500px;
    overflow-x: hidden;
    max-height: 600px;
    overflow-y: scroll;

    .left-container {
      border: 1px solid $unilever-disabled-grey;
      margin-left: 12px;
      margin-right: 15px;
      width: 47%;
      height: 480px;

      .left-content {
        display: flex;
        flex-direction: column;

        padding: 15px 6px 11px 5px;

        h2 {
          font-family: 'unilever-shilling';
          font-size: 14px;
          color: $main-text-black;
        }

        p {
          font-family: 'unilever-shilling';
          font-size: 12px;
          font-weight: 400;
          color: $grey-shade-1;
        }

      }

      .textinput-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 18px;
        padding: 0px;

        .disabled {
          margin-bottom: 16px;
          opacity: 0.3;
        }

        .enabled {
          opacity: 1;
          margin-bottom: 16px;
        }
      }
    }

    .right-container {
      border: 1px solid $unilever-disabled-grey;
      margin: 0px;
      padding: 0px;
      display: flex;
      justify-content: center;
      width: 48%;
      height: 480px;
      overflow-y: scroll;


      .right-content {
        display: flex;
        flex-direction: column;
        padding: 15px 0px 11px 8px;
        overflow-y: scroll;

        h2 {
          font-family: 'unilever-shilling';
          font-size: 14px;
          color: $main-text-black;
        }

        p {
          font-family: 'unilever-shilling';
          font-size: 12px;
          font-weight: 400;
          color: $grey-shade-1;
        }

        .list-item-container {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          padding: 0px 0px 0px 4px;

          .list-item-parent {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 15px;

            .list-item {
              display: flex;
              width: 80%;
              margin-top: 9px;
              border: 1px solid $table-header;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              padding: 4px 0px 0px 15px;

              p {
                color: $grey-shade-1;
                font-weight: 400;
                font-size: 12px;
                margin-top: 10px;

              }
            }

            .error {
              background-color: #FFEBEA;

              input{
                background-color: #FFEBEA;
              }
            }

            .ed-input {
              width: 100%;

              &>div {
                margin-bottom: 0px !important;
              }
            }
          }
        }

        .new-target {
          background-color: rgb(0 177 144 / 20%);

          input {
            background-color: rgb(0 177 144 / 0%);
          }
        }

        input:focus {
          box-shadow: none;
        }
      }

      .error-message {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        color: #FF2828;
        padding-left: 28px;
      }

    }

  }
}