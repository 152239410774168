@import './../../../../styles/_variables.scss';

.critical-attr-toast {
  .top-0 {
    top: 6rem !important;
    right: 2rem !important;
  }
}

.critical-attr-table {
  .table-component {
    th {
      height: 3rem;
    }
    tr {
      height: 4rem;
      td {
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

.critical-attr-filters {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  &-title {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
}

.table-actions {
  display: flex;
  gap: 0.5rem;
}
