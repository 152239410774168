@import "src/styles/main";

.tools-container {
  .left-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top:90px;
    h2 {
      font-family: 'unilever-shilling-medium';
      font-size:16px;
    }
    p {
      font-family: 'unilever-shilling';
      font-size: 12px;
      text-align: justify;
    }

  }
  .right-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}