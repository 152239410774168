@import './../../../../styles/_variables.scss';

.status-cell {
  .status {
    padding: 0.2rem 1rem;
    border-radius: 5px;
    color: $unilever-white;
    white-space: nowrap;
  }
  .success {
    background-color: $success-tag;
  }
  .failure {
    background-color: $ternary-color-red;
  }
  .in_progress {
    background-color: $confidence-medium;
  }
}

.fv-record-filename-cell {
  color: $primary-color;
  text-decoration: underline;
}

.fv-table-actions {
  .download-btn {
    background-color: $primary-color;
    border-radius: 0;
    font-family: 'unilever-shilling';
    .mantine-Button-inner {
      .mantine-Button-rightIcon {
        width: 1.1rem;
        height: auto;
      }
    }
  }
  .status-btns-group {
    border-left: 1px solid $main-text-black;
    padding-left: 1rem;
    gap: 1rem;
    .mantine-Button-root {
      border-radius: 0;
      border-color: $primary-color;
      color: $primary-color;
      border-width: 2px;
      &:first-of-type:not(:last-of-type),
      &:last-of-type:not(:first-of-type),
      &:not(:first-of-type):not(:last-of-type) {
        border-right-width: 2px;
        border-left-width: 2px;
      }
    }
    .active {
      background-color: $table-header;
    }
  }
}

.fv-table-actions {
  display: flex;
  gap: 1rem;
}
