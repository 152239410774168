@import "src/styles/variables";

.leadership-dashboard {
    .top-row-container {
        display: flex;
        padding-inline-start: 12px;
        justify-content: space-between;
    }

    .home-page-title {
        h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 0.2px;
            color: #000000;
        }

        p {
            font-family: 'unilever-shilling';
            margin-block-start: 10px;
            margin-block-end: 30px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 30px;
            letter-spacing: 0.2px;
            color: $grey-shade-1;
        }
    }

    .filters-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin: 0px;
        
        .filter-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            // padding-inline-end: 12px;
            gap: 12px;
            justify-content: flex-end;

            &:last-child {
                padding-left: 10px;
            }
        }
        
        .filters-container-txt {
            font-size: 12px;
            padding-right: 3px;
        }
        
    }

    .graphs-card {
        padding-block-start: 24px;
        padding-block-end: 30px;
        padding-inline-start: 22px;
        padding-inline-end: 22px;
        margin-block-start: 31px;
        box-shadow: -10px 10px 20px 0px rgba(30, 30, 30, 0.05);
        background-color: $unilever-white;
    }

    .heading-container {
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            color: $main-text-black;
            margin-bottom: 15px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: $grey-shade-1;
            opacity: 0.8;
        }
    }
}