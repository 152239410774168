@import '/src/styles/variables';

.mantine-SegmentedControl-label {
  color: $primary-color;
  border: solid $primary-color;
  border-width: 1px 1px;
}

.segmented-tab {
  margin-left: -4px;
  .mantine-SegmentedControl-indicator {
    background-color: $primary-color;
    color: $unilever-white;
  }
  .mantine-SegmentedControl-control {
    .mantine-SegmentedControl-label {
      color: $primary-color;
      border: solid $primary-color;
      text-transform: capitalize;
      border-width: 1px 0 1px 1px;
      &:hover {
        background-color: $primary-color;
        color: $unilever-white;
      }
    }
    &:not(:first-of-type) {
      border-width: 0;
      color: $unilever-white;
    }
    &:last-child {
      .mantine-SegmentedControl-label {
        border-width: 1px;
      }
    }
    &Active {
      .mantine-SegmentedControl-label {
        color: $unilever-white;
      }
    }
  }
}
