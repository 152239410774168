@import 'src/styles/main';

.ed-datepicker {
  border: 1px solid $unilever-disabled-grey;
  padding: 11px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  width: 100%;
  color: #73706e;
  &:disabled {
    background-color: $disabled-bg-color;
  }
  &:focus-visible {
    outline: 0 !important;
  }
}

.datepicker-close-icon {
  &::after {
    position: absolute;
    top: 6px !important;
    background-color: white !important;
    right: 12px !important;
    width: 10px !important;
    height: 10px !important;
    font-size: 24px !important;
    color: rgb(31, 54, 199) !important;
  }
}

.react-datepicker__input-container {
  &:focus-visible {
    outline: 0;
  }
}