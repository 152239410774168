@import 'src/styles/variables';

.harmonized-pie-chart {
  p.fail-status {
    color: $failed-tag !important;
  }

  p.success-status {
    color: $success-tag !important;
  }

  p.in-process-status {
    color: $unilever-disabled-grey !important;
  }

  .card-template {
    .card-template-header {
      .card-template-heading {
        color: $main-text-black;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  .pin-icon {
    margin-right: 1rem;
  }
}
