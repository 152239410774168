@import 'src/styles/variables';

.confidence-mapping {
  .download-container {
    color: $primary-color;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    padding-right: 30px;
  }
  .filter-container {
    width: 45%;
  }

  .recharts-cartesian-axis {
    .recharts-cartesian-axis-ticks {
      .recharts-cartesian-axis-tick {
        .recharts-cartesian-axis-tick-value {
          font-size: 0.7rem;
        }
        .recharts-cartesian-axis-tick-line {
          display: none;
        }
      }
    }
  }
  .recharts-yAxis {
    .recharts-label,
    .recharts-text {
      font-size: 0.75rem;
      font-weight: bold;
    }
  }
  .recharts-xAxis {
    .recharts-label,
    .recharts-text {
      font-size: 0.85rem;
    }
  }
  .data-providers {
    display: flex;
    gap: 1.5rem;
    padding-left: 0;
    .data-provider {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      &-dot {
        width: 0.35rem;
        height: 1.25rem;
      }
      &-name {
        list-style: none;
        font-size: 0.9rem;
        color: $tab-font-grey;
        letter-spacing: 0.1px;
      }
      .dot-0 {
        background: $confidence-high;
      }
      .dot-1 {
        background: $confidence-medium;
      }
      .dot-2 {
        background: $confidence-low;
      }
    }
  }
  .data-provider-title {
    font-size: 0.8rem;
    color: $tab-font-grey;
    font-weight: bold;
    margin: 0;
    height: fit-content;
  }
  .data-provider-total-count {
    font-size: 1.25rem;
    color: $table-body-font-color;
    margin: 0;
    font-family: 'unilever-shilling-bold';
  }
  .data-provider-total-req {
    font-size: 0.85rem;
    color: $tab-font-grey;
    font-weight: 100;
  }
  .recharts-surface {
    min-height: 24rem;
  }
}

@media (min-width: 1500px) and (max-width: 1600px) {
  .confidence-mapping {
    .recharts-surface {
      min-height: 28rem;
    }
  }
}
@media (min-width: 1600px) and (max-width: 1800px) {
  .confidence-mapping {
    .recharts-surface {
      min-height: 30rem;
    }
  }
}
@media (min-width: 1800px) {
  .confidence-mapping {
    .recharts-surface {
      min-height: 35rem;
    }
  }
}
