@import 'src/styles/main';

.navbar-template {
  position: fixed;
  top: 0;
  left: 12.65rem;
  background: #083a23;
  padding: 10px 30px 9px 29px;
  height: fit-content;
  margin: 0px;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
  .navbar-search {
    padding-inline: 0px;
    margin: 0px;

    .search {
      width: 130%;
      max-width: 440px;
      margin-left: 1.25rem;
      .search-input-field {
        .card-template-header-left-icon {
          top: 14px;
        }
        .search-input-field-text {
          height: 40px !important;
        }
      }
    }
  }

  .container-center {
    display: inline-flex;
    justify-content: flex-end;
    padding-inline: 0px;
    .zoom-controls-container {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      margin-right: 4px;
      gap: 6px;
      padding-block: 2px;

      .reset-zoom {
        padding: 6px 12px;
      }
    }
    .nav-dropdown-container {
      background-color: transparent;
      border: 0px;
      margin-left: 24px;
      padding: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: auto;
    }
    .screen-reader-access {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 109.8%;
      color: #f5f5f5;
      .separator {
        width: 1px;
        height: 20px;
        margin-inline: 13px;
        border: 1px solid $border-color-grey;
      }
      p {
        margin-top: 12px;
      }
    }
  }
  .container-right {
    padding: 0px;
    margin: 0px;
    height: 100%;
    padding-block: 8px;
    padding-inline: 0px;
    .icon-container {
      display: flex;
      width: 80%;
      gap: 27px;
      justify-content: flex-start;
    }
    .profile-dropdown {
      .nav-link {
        .profile-pic-wrapper {
          .profile-down-arrow {
            transition: 0.5s;
          }
        }
      }
      .profile-pic-wrapper {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .profile-pic {
          border-radius: 50%;
          width: 2rem;
          height: 2rem;
          object-fit: cover;
        }
      }
      .dropdown-menu[data-bs-popper] {
        right: -1rem;
        left: auto;
        border-radius: 0;
        padding: 0.25rem;
        min-width: 8.75rem;
        border: none;
        box-shadow: $profile-dropdown-card-shadow;

        .dropdown-item {
          font-size: 0.875rem;
          font-weight: 600;

          &:hover {
            background: $table-header-data-cell-color;
          }
        }
      }
      .dropdown-toggle {
        &::after {
          content: none;
        }
      }
      &__menu {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 0.5rem;
        img {
          object-fit: contain;
        }
      }
    }

    .show {
      .nav-link {
        .profile-pic-wrapper {
          .profile-down-arrow {
            transform: rotate(180deg);
            transition: 0.5s;
          }
        }
      }
    }
  }
}

@media (max-width: 1202px) {
  .container-center {
    margin-inline-start: -38px;
  }
}
