@import "src/styles/main";

.tab-component {
  padding: 0px;

  .nav-link {
    height: 34px;
    font-weight: 600;
    color: $primary-color;
    font-family: 'unilever-shilling-medium';
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    padding: 6px 20px 8px 20px;
    background-color: $unilever-white;
    font-size: 13px;
    border: 1px solid $primary-color;
    border-right: none;

    &:hover {
      border-color: $primary-color;
    }
  }

  .nav-link.active {
    background: $unilever-blue;
    color: $unilever-white;
    border: 1px solid $primary-color;
  }

  .nav-item:last-child {
    .nav-link {
      border: 1px solid $primary-color;
    }
  }

}