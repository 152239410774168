@import '/src/styles/variables';

.fv-mapping-table {
  margin-top: 2rem;
  &__header {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    .file-name-status {
      display: flex;
      margin: 0;
      &__title,
      &__name,
      &__status {
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        font-weight: 400;
        font-family: 'unilever-shilling-medium';
        color: $grey;
        display: inline-block;
      }
      &__name {
        color: $primary-color;
        font-family: 'unilever-shilling';
        margin-right: 1.5rem;
        text-decoration: underline;
      }
      &__status {
        margin-left: 0.25rem;
        font-weight: 300;
        font-family: 'unilever-shilling';
        padding: 0.15rem 0.5rem;
        border-radius: 10px;
        max-height: 1.8rem;
      }
      .success {
        background-color: $unmapped-active-button;
        color: $success-tag;
      }
      .failure {
        background-color: $delete-bg;
        color: $ternary-color-red;
      }
      .progress {
        background-color: $cell-edit-color;
        color: $confidence-medium;
      }
    }
    &__row {
      background-color: $table-empty-cell-color;

      .mark-as-critical-header-col {
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        &__icon {
          width: 0.5rem;
          height: auto;
          object-fit: contain;
        }

        &__text {
          font-size: 0.9rem;
          font-family: 'unilever-shilling-medium';
          color: $main-text-black;
        }
      }

      .previous,
      .current {
        padding: 0;
      }

      .previous-col,
      .current-col {
        display: table;
        border-collapse: collapse;
        border-style: hidden;
        background-color: $table-empty-cell-color;
        tr {
          background-color: $table-empty-cell-color;
        }

        &__header {
          text-align: center;
          color: $main-text-black;
          font-size: 0.9rem;
          font-family: 'unilever-shilling-medium';
          padding: 1rem 1rem 0.5rem;
        }
        &__bottom {
          font-size: 0.9rem;
          font-family: 'unilever-shilling';

          &__cell {
            padding: 0.25rem;
          }

          &__errors {
            display: flex;
            gap: 0.25rem;
            align-items: center;
            &__icon {
              margin-top: 0.25rem;
              width: 0.9rem;
              height: auto;
              object-fit: contain;
              filter: grayscale(1);
            }
          }
        }
      }
      .target-unilever-database {
        font-size: 0.9rem;
        font-family: 'unilever-shilling-medium';
        color: $main-text-black;
        padding-top: 2rem;
        text-align: center;
      }
    }
  }
  .critical-icon {
    border: none;
    background: none;
    width: 2rem;
    height: 2rem;
    margin-left: -0.75rem;
  }
  &__footer {
    display: flex;
    padding: 1rem;
    gap: 1rem;
    justify-content: end;
    border: 1px solid $border-color-grey;
    border-top: 0;
  }
  &__body {
    background-color: $unilever-white;
    .fv-mapping-table-body-row {
      background-color: $unilever-white;
    }
    .mark-as-critical-col-icon {
      width: 0.5rem;
      height: auto;
      object-fit: contain;
    }
    .previous-col-data-cell {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__value {
        margin: 0;
      }
      &__icon {
        cursor: pointer;
      }
    }
  }

  .fv-mapping-table__paper {
    box-shadow: none;
    margin-top: 1rem;
    border-radius: 0;
    border: none;
    .fv-mapping-table__container {
      table {
        th,
        td {
          border: 1px solid $border-color-grey;
          font-size: 0.9rem;
        }
      }
    }
  }
  &__footer,
  &__actions {
    display: flex;
    gap: 1rem;
    align-items: center;
    .mantine-Button-root {
      border-color: $primary-color;
      color: $primary-color;
      padding: 0.5rem 0.75rem;
      background-color: $unilever-white;
      border-radius: 0;
      .mantine-Button-label {
        font-size: 0.8rem;
        font-family: 'unilever-shilling';
      }
      .mantine-Button-rightIcon {
        width: 1rem;
        height: auto;
      }
      &[data-disabled] {
        opacity: 0.5;
      }
    }
    .mantine-ButtonGroup-root {
      .mantine-Button-root {
        &:first-child {
          background: none;
        }
        .mantine-Button-rightIcon {
          margin: 0;
        }
      }
    }

    .primary-btn {
      background-color: $primary-color;
      color: $unilever-white;
    }
  }
}
