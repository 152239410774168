.remapping-table {
  th,
  td {
    border-collapse: collapse;
  }

  td {
    word-break: break-all;
    padding: 0.8rem;
  }
}

tbody td {
  font-size: 0.9rem;
}

.drawer-header {
  margin: 1rem;
}
