@import 'src/styles/main';

.access-denied-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.access-denied-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: $unilever-blue;
}
