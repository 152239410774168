.mantine-Breadcrumbs-breadcrumb:last-child {
  font-weight: 600;
  margin: 0;
  pointer-events: none;
  // color: #1f36c7;
  color: #09a75e;
}

.mantine-Breadcrumbs-breadcrumb {
  text-decoration: none;
  color: #73706e;
  text-transform: capitalize;
}

.mantine-Breadcrumbs-root {
  margin: 0;
}

.mantine-breadcrumb-container {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  margin: 1rem 0 1.5rem 0;
}
