@import 'src/styles/main';

.sidebar-container {
  height: 100%;
  position: fixed;
  width: 13.625rem;
  top: 0;
  margin: 0;
  border-right: 1px solid $unilever-white;
  background-color: #083a23;
  display: flex;
  z-index: 11;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .sidebar-logo {
    background: none;
    border: none;
  }
  .sidebar-header {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .sidebar-header-edith-icon {
      width: 79px;
      height: 30px;
    }
  }

  .logo-name {
    margin-top: 1rem;
    color: white;
    font-size: larger;
    font-weight: 500;
    font-family: unilever-shilling;
    font-size: 1.8rem;
  }
  .sidebar-menu-container {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    .menu-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      background: none;
      border: none;
      color: $unilever-white;
      padding: 0.8rem 1.2rem;
      margin: 0 2rem 0 0.6875rem;

      &__icon {
        width: 1.25rem;
        height: auto;
        object-fit: contain;
        filter: invert(1) brightness(100);
      }
      &__name {
        margin: 0;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1rem;
        letter-spacing: 0.26px;
        text-align: left;
      }
      .container-right {
        .dropdown-toggle {
          &::after {
            content: '';
            border-top: 5px solid transparent;
            border-right: 0;
            border-bottom: 5px solid transparent;
            border-left: 7px solid;
            float: right;
          }
        }
      }
    }
    .active {
      background-color: $unilever-white;
      color: $main-text-black;
      position: relative;
      &::after {
        content: '';
        width: 3px;
        height: 100%;
        background-color: $unilever-white;
        position: absolute;
        top: 0;
        right: -1rem;
      }
      .menu-item__icon {
        filter: none;
      }
      .menu-item__name {
        font-weight: 600;
        color: #73706e;
      }
    }
  }
  .current-login {
    position: absolute;
    bottom: 2rem;
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    background-color: $login-time-bg-color;
    &__icon {
      margin-top: 0.25rem;
      width: 19px;
      height: 19px;
      object-fit: contain;
    }
    &__text {
      &__title,
      &__time {
        margin: 0;
        color: $unilever-white;
        font-size: 0.75rem;
      }
      &__title {
        margin-bottom: 0.25rem;
      }
    }
  }
}
