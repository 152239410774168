@import 'src/styles/variables';

.skeleton-loader {
  width: 100%;
  .placeholder-lg {
    .placeholder {
      min-height: 2.75rem;
      display: flex;
    }
  }
}
