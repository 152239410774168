@import './../../../../styles/_variables.scss';
.table-top-wrapper {
  margin-top: 1.5rem;
  border: 1px solid $table-border;
  border-width: 1px 1px 0;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 1rem;
  background-color: $table-bg-color;

  &__title {
    font-size: 0.875rem;
    color: $grey-shade-1;
    margin-bottom: 0;
    margin-left: 0.875rem;
  }
}

.table-bottom-wrapper {
  margin: 0;
  border: 1px solid $table-border;
  border-width: 0 1px 1px;
  background-color: #fbfbfb;
  padding: 1rem 3rem;
  align-items: center;
  justify-content: space-between;
  &__action-btns {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.table-top-wrapper,
.table-bottom-wrapper {
  .btn-container.secondary.pressed {
    border: none;
  }
}

.sml-pcat-toast {
  .top-0 {
    top: 6rem !important;
    right: 2rem !important;
  }
}

.sml-pcat-table {
  border: 1px solid $table-border;
  .table-component {
    margin-top: 0;
    border: none;
    th {
      height: 3rem;
    }
    tr {
      height: 4rem;
      td {
        line-height: 20px;
      }
    }
  }
}

.sml-pcat-filters {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  &-title {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
}

.bottom-text{
  display: flex;
}