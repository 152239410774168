.statusCheckTable {
    width: fit-content;
    overflow: auto;
}

.message_popup {
    display: -webkit-box;
    max-width: 300px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.description_popup {
    display: -webkit-box;
    min-width: 500px;
    /* -webkit-line-clamp: 5; */
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.check-name{
    writing-mode: vertical-lr;
    text-orientation: mixed;
    rotate: 180deg;
    border: 1px solid rgba(0, 0, 0, 0.469) !important;
}

.table-body-dq-chek-grid{
    height: 40px;
}

.table-header-dq-check{
    height: 240px;
}

.table-body td {
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.469) !important;
  }
  
  .check-type {
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.469) !important;
    width: fit-content;
  }