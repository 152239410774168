@import '/src/styles/variables';

.cell-control-filters {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin-bottom: 1rem;

  &-title {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
}

.cell-control-table-actions {
  background-color: $unilever-white;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cell-controls {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-right: 3rem;

    &__total {
      margin: 0;
      padding-right: 0.5rem;
      border-right: 2px solid $grey;
      font-size: 0.875rem;
      font-weight: 600;
    }
  }
}

.cell-status {
  margin: 0;
  text-transform: capitalize;
  font-weight: 600;
  font-family: 'unilever-shilling-medium';
  font-size: 0.875rem;
}

.cell-activated {
  color: $data-provider-1;
}

.cell-deactivated {
  color: $grey;
}

.cell-control-switch {
  display: flex;
  align-items: end;
  justify-content: center;

  .form-switch {
    padding-left: 3.5rem;
  }

  .form-check-input {
    cursor: pointer;
    border-color: $grey-shade-1;
    border-width: 2px;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272.5%27 fill=%27rgba%28115, 112, 110, 1%29%27/%3e%3c/svg%3e');

    &:checked {
      background-color: $data-provider-1;
      border-color: $data-provider-1;
      background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272.5%27 fill=%27%23fff%27/%3e%3c/svg%3e');
    }
  }
}

.cell-control-table {
  .table-component {
    margin-top: 0;
    border: none;

    th {
      height: 3rem;
    }

    tr {
      height: 4rem;

      td {
        line-height: 20px;
      }
    }
  }

  .disable-row {
    opacity: 0.5;
  }
}

.checkbox {
  width: 1.2rem;
  height: 1.2rem;
}

.bottom-Action {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottom-text {

  margin: 0;
  text-transform: capitalize;
  font-weight: 600;
  font-family: 'unilever-shilling-medium';
  font-size: 0.95rem;

}