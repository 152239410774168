@import '/src/styles/variables';

.fv-mapping-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  .file-name-status {
    margin: 0;
    &__title,
    &__name,
    &__status {
      font-size: 0.9rem;
      letter-spacing: 0.5px;
      font-weight: 400;
      font-family: 'unilever-shilling-medium';
      color: $grey;
    }
    &__name {
      color: $primary-color;
      font-family: 'unilever-shilling';
      margin-right: 1.5rem;
      text-decoration: underline;
    }
    &__status {
      font-weight: 300;
      font-family: 'unilever-shilling';
      padding: 0.15rem 0.5rem 0.3rem;
      border-radius: 10px;
    }
    .success {
      background-color: $unmapped-active-button;
      color: $success-tag;
    }
    .failure {
      background-color: $delete-bg;
      color: $ternary-color-red;
    }
    .in_progress {
      background-color: $cell-edit-color;
      color: $confidence-medium;
    }
  }
  .data-details {
    border-radius: 5px;
    display: inline-flex;
    width: fit-content;
    gap: 2rem;
    background-color: $table-empty-cell-color;
    padding: 1rem 0.5rem;
    &__detail {
      margin: 0;
      &__key,
      &__value {
        font-size: 0.9rem;
        letter-spacing: 0.5px;
        font-weight: 400;
        font-family: 'unilever-shilling-medium';
        color: $grey;
      }
      &__value {
        color: $primary-color;
        font-family: 'unilever-shilling';
      }
    }
  }
}
