@import "src/styles/main";

.ed-input {
  .input-field {
    width:100%;
    // background-color: red;
    border-radius:0px;
    font-size:12px;
    border:1.5px solid $table-border;
    padding:10px;
    &.borderless {
      border:0px;
      width:100%;
      border-radius: 0px;
      // background-color: blue;
    }
  }
}