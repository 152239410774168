@import "src/styles/main";

.about-container {
  
  .content-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top:90px;
    p {
      font-family: 'unilever-shilling';
      font-size: 14px;
      text-align: justify;
    }

  }
  .image-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}