@import 'src/styles/main';

table {
  border-spacing: 0;
  border: 1px solid #ededed;
  overflow-x: scroll;
}

table tr:last-child td {
  border-bottom: 0;
}

table th {
  background-color: $table-header;
  padding: 10px 16px 10px 16px;
  font-weight: 400;
  line-height: 20px;
  color: $table-header-font;
}

#table-header {
  background-color: #d7f3e6 !important;
}
table td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid $table-border;
  position: relative;
  font-family: 'unilever-shilling';
  font-weight: 400;
}

table th:last-child,
table td:last-child {
  border-right: 0;
}

table tr {
  background-color: #fafafa;
}

table th::before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: '';
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
}

table th.sort-asc::before {
  border-bottom: 5px solid #22543d;
}

table th.sort-desc::before {
  border-top: 5px solid #22543d;
}

.App {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.badge {
  background-color: #9ae6b4;
  color: #22543d;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 4px;
  font-weight: bold;
  text-transform: uppercase;
}

.table-component {
  margin: 10px 0px;

  input {
    padding: 10px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: none;
  }

  thead tr th {
    background-color: $table-empty-cell-color;
  }

  thead tr:first-child th {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.26px;
    line-height: 16px;
    opacity: 70%;
    &.data-exist {
      vertical-align: middle !important;
    }
  }

  thead tr th {
    font-weight: 600;
    font-size: 16px;
  }

  td {
    border: 1px solid $table-border;
  }

  tbody tr td {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: $table-body-font-color;
    padding: 10px 16px;
  }

  tbody .checkbox {
    margin: auto;
  }

  thead tr:nth-child(2) th {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    opacity: 0.7;
  }
}

tbody tr:hover {
  background-color: #eff1fc;
}

.table-component-highMedium {
  margin: 10px 0px;

  input {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: none;
  }

  thead tr th {
    background-color: $table-empty-cell-color;
  }

  thead tr:first-child th {
    color: #000000;
    font-weight: 600;
    font-size: 16px;

    &.data-exist {
      vertical-align: top;
      border: 1px solid $table-border;
      border-bottom: 2px solid $table-border;
    }
  }

  thead tr th {
    font-weight: 600;
    font-size: 16px;
    border: 1px solid $table-border;
  }

  td {
    border: 1px solid $table-border;
  }

  tbody tr td {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    color: $table-body-font-color;
    padding: 10px 16px;
  }

  tbody .checkbox {
    margin: auto;
  }
}

tbody tr:hover {
  background-color: #d7f3e6;
}

tbody {
  .table-row {
    .edit-highlight {
      background-color: $cell-edit-color;
    }
    .update-highlight {
      background-color: $cell-update-color;
    }
    &__column {
      .cell-input {
        width: 100%;
        background-color: transparent;
        padding: 0;
        margin: 0;
        font-size: 0.875rem;
        border: none;
        color: $table-body-font-color;
        &:focus {
          outline: none;
        }
      }
      .table-cell-loading {
        .placeholder-lg {
          .placeholder {
            min-height: 1.25rem;
            border-radius: 0.2rem;
          }
        }
      }
    }
  }
  .add-row {
    border: 2.5px solid $cell-edit-color;
    position: relative;

    .cell-input {
      border: 1px solid $primary-color;
      border-radius: 0;
      padding: 1rem 0.5rem;
    }
    .table-row__column {
      // padding: 0.15rem 0.25rem;
    }
  }
  .show-success-border {
    border: 2.5px solid $cell-update-color;
  }
  .table-row,
  .add-row {
    position: relative;
    .delete-btn {
      position: sticky;
      top: 0;
      right: 0;
      width: 30px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $delete-bg;
      border: 2px solid $cell-edit-color;
      &__btn {
        width: 14px;
        height: 14px;
        object-fit: contain;
      }
      &:hover {
        background-color: $delete-bg-hover;
      }
    }
  }
}

.table-with-actions {
  th:last-child,
  td:last-child {
    position: sticky;
    right: -5px;
    z-index: 1;
    background-color: $dashboard-bg;
  }

  th:last-child {
    background-color: $table-header;
  }
}
