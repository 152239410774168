@import "src/styles/variables";

.task-updates {
    .task-name {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: $grey-shade-1;
    }

    .task-count {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: $grey-shade-1;
    }

    .task-container {
        border-bottom: 1px solid $table-border;
        padding: 18px 18px 18px 0px;

        &:last-child {
            border-bottom: none;
        }
    }

    .task-list-container {
        overflow-y: scroll;
    }

    .tasks-header {
        .heading-container {
            h3{
                color: $main-text-black;
                opacity: 0.7;
            }
        }
    }
}