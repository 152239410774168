@import "variables";

/* Helpers CSS */

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.float-right {
  float: right !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline !important;
}

.text-primary {
  color: $primary-color;
}

.text-white {
  color: #FFFFFF !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.float-none {
  float: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.w-100 {
  width: 100% !important;
}


/* Padding */

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: ($spacer-y * .25) !important;
}

.p-2 {
  padding: ($spacer-y * .5) !important;
}

.p-3 {
  padding: ($spacer-y * .75) !important;
}

.p-4 {
  padding: ($spacer-y * 1) !important;
}

.p-5 {
  padding: ($spacer-y * 1.25) !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: ($spacer-y * .25) !important;
}

.pt-2 {
  padding-top: ($spacer-y * .5) !important;
}

.pt-3 {
  padding-top: ($spacer-y * .75) !important;
}

.pt-4 {
  padding-top: ($spacer-y * 1) !important;
}

.pt-5 {
  padding-top: ($spacer-y * 1.25) !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: ($spacer-y * .25) !important;
}

.pr-2 {
  padding-right: ($spacer-y * .5) !important;
}

.pr-3 {
  padding-right: ($spacer-y * .75) !important;
}

.pr-4 {
  padding-right: ($spacer-y * 1) !important;
}

.pr-5 {
  padding-right: ($spacer-y * 1.25) !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: ($spacer-y * .25) !important;
}

.pb-2 {
  padding-bottom: ($spacer-y * .5) !important;
}

.pb-3 {
  padding-bottom: ($spacer-y * .75) !important;
}

.pb-4 {
  padding-bottom: ($spacer-y * 1) !important;
}

.pb-5 {
  padding-bottom: ($spacer-y * 1.25) !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: ($spacer-y * .25) !important;
}

.pl-2 {
  padding-left: ($spacer-y * .5) !important;
}

.pl-3 {
  padding-left: ($spacer-y * .75) !important;
}

.pl-4 {
  padding-left: ($spacer-y * 1) !important;
}

.pl-5 {
  padding-left: ($spacer-y * 1.25) !important;
}

/* Margins */

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: ($spacer-y * .25) !important;
}

.mt-2 {
  margin-top: ($spacer-y * .5) !important;
}

.mt-3 {
  margin-top: ($spacer-y * .75) !important;
}

.mt-4 {
  margin-top: ($spacer-y * 1) !important;
}

.mt-5 {
  margin-top: ($spacer-y * 1.25) !important;
}

.mb-2 {
  margin-bottom: ($spacer-y * .5) !important;
}

.mb-3 {
  margin-bottom: ($spacer-y * .75) !important;
}

.mb-4 {
  margin-bottom: ($spacer-y * 1) !important;
}

.mb-5 {
  margin-bottom: ($spacer-y * 1.25) !important;
}

.ml-2 {
  margin-left: ($spacer-x * .5) !important;
}

.ml-3 {
  margin-left: ($spacer-x * .75) !important;
}

.ml-4 {
  margin-left: ($spacer-x * 1) !important;
}

.ml-5 {
  margin-left: ($spacer-x * 1.25) !important;
}

.mr-1 {
  margin-right: ($spacer-x * .25) !important;
}

.mr-2 {
  margin-right: ($spacer-x * .5) !important;
}

.mr-3 {
  margin-right: ($spacer-x * .75) !important;
}

.mr-4 {
  margin-right: ($spacer-x * 1) !important;
}

.mr-5 {
  margin-right: ($spacer-x * 1.25) !important;
}
.mr-6 {
  margin-right: ($spacer-x * 1.5) !important;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-baseline {
  justify-content: baseline;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.aligh-item-baseline {
  align-items: baseline;
}

.aligh-item-center {
  align-items: center;
}

.aligh-item-end {
  align-items: flex-end;
}

.aligh-item-start {
  align-items: flex-start;
}