/* Color palette */
// $primary-color: #1f36c7;
$primary-color: #09a75e;
$primary-dark-color: #033c22;
$primary-light-color: #5c92b6;
$secondary-green-color: #11532b;
$secondary-yellow-color: #f5be3c;
$main-text-black: #000000;
$grey: #6b6d7d;
$scrollbar-thumb: #8390b7;
$line-seperator: #e8eaf0;
$ternary-color-red: #b90808;
$ternary-color-orange: #fd7b02;
$ternary-color-blue: #67b0f5;
// $unilever-blue: #1f36c7;
$unilever-blue: #09a75e;
$nav-btn-pink: #e2bee8;
$unilever-white: #ffffff;
// $unilever-white: #d7f3e6;
$grey-shade-1: #73706e;
$pink-shade-2: #fca6de;
// $table-header: #ebedfa;
$table-header: #d7f3e6;
$table-header-font: #282828;
$unilever-dark-blue: #033c22;
$unilever-hover-blue: #008651;
$unilever-disabled-grey: #d9d9d9;
$tab-font-grey: #636466;
$dashboard-bg: #f5f5f5;
$table-border-vertical: #979797;
$table-border: #e2e2e2;
$dropdown-font: #576571;
// $filter-dashboard-font:#202224;
//border color
$border-color-grey: #b4b4b4;
$nav-btn-border-pink: #eeeeee;
$data-provider-1: #008651;
$data-provider-2: #ff0a7a;
$data-provider-3: #9c44c0;
$confidence-high: #00b190;
$confidence-medium: #ff9e00;
$confidence-low: #bdbdbd;
$login-time-bg-color: rgba(249, 249, 249, 0.1);

$delete-bg: rgba(255, 153, 152, 0.5);
$delete-bg-hover: rgba(255, 153, 152, 0.75);
$secondary-dark-green-color: #07391b;
$secondary-light-green-color: #57866a;

// Spacer Variables
$spacer-x: 1rem;
$spacer-y: 1rem;

// Table
$table-border: #e5e5e5;
// $table-empty-cell-color: #ebedfa;
$table-empty-cell-color: #d7f3e6;
$table-header-data-cell-color: #e0e4ff;
$table-header-group-data-cell-color: #018837;
$table-body-font-color: #73706e;
$checkbox-border: #8c9196;
$link-blue: #57866a;
$unmapped-active-button: rgba(0, 177, 144, 0.05);
$success-tag: #00b190;
$failed-tag: #ff544f;
$progress-tag: #ffc000;
$footer-bg: #fcfcfc;
$disabled-bg-color: rgba(167, 163, 163, 0.11);
$table-bg-color: #fafafa;
$cell-edit-color: rgba(245, 190, 60, 0.3);
$cell-update-color: rgba(17, 83, 43, 0.3);
$status-blue: #047d46;
$section-grey: #eaeaea;

// volatility
$error-bg: #f5f8ff;
$light-bg: #f5f8ff;

// Login Screen
$bg-color-login: #047d46;

//shadows
$profile-dropdown-card-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);
